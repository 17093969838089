import React from "react";
import Cards from "./Cards";

const Features = () => {
  return (
    <div className="relative w-full h-[160vh] md:h-[130vh] bg-gradient-to-r from-teal-500 to-blue-600 flex justify-center items-center">
      <img className="absolute bottom-0 md:-bottom-32 left-0 z-10 opacity-30" src="/rb_15769.png" alt="" />
      <div className="absolute px-10 md:px-0 z-20 inset-0 flex justify-center items-start pt-24 bg-black bg-opacity-50">
        <div className="w-full max-w-screen-lg">
          <div className="grid place-items-center pb-10">
            <h1 className="text-4xl font-bold text-white mb-4 text-center">
              Explore Our Exclusive Features
            </h1>
            <p className="text-white text-center">
              Discover why our platform is the ultimate destination for fantasy sports lovers.
            </p>
          </div>
          <div className="w-full h-auto">
            <Cards />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
