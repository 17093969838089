import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div className="w-full h-auto flex flex-col justify-center items-center relative bg-gradient-to-br from-teal-400 via-gray-100 to-gray-300">
      <div className="w-full h-screen flex flex-col justify-center items-center text-center">
        <h1
          className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-teal-600 to-gray-800"
          data-aos="fade-down"
        >
          Experience the Excitement of Fantasy Sports!
        </h1>
        <p
          className="text-gray-700 text-lg md:text-xl mt-4 max-w-xl"
          data-aos="fade-up"
        >
          Experience the Joy of Building Your Dream Team!
        </p>
        <div className="flex justify-center mt-10">
          <button
            onClick={() => window.location.assign("https://wa.link/indraadsgg")}
            className="bg-teal-500 hover:bg-teal-600 text-white font-semibold px-8 py-3 rounded-md shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
