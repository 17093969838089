import React from "react";
import Features from "./Features";
import Aboutus from "./Aboutus";
import Home from "./Home";
import Play from "./Play";
import Review from "../components/Review";
import Newsletter from "./Newsletter";
import LiveScores from "./Livescore";
import NoRealMoneySection from "./Norealmoney";
const HomePage = () => {
  return (
    <div>
      <section id="home">
        <Home />
      </section>
      <section id="features">
        <Features />
      </section>
      <section id="aboutus">
        <Aboutus />
      </section>
      {/* <section id="Play">
       
      </section> */}
      <Newsletter/>
      <LiveScores/>
      <Review />
    </div>
  );
};

export default HomePage;
