import React from "react";

const Aboutus = () => {
  return (
    <div className="relative w-full bg-gradient-to-r from-purple-500 to-indigo-400 py-16 md:py-20 flex justify-center items-center">
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-30 z-10"></div>
      <div className="relative z-20 w-full max-w-screen-xl px-6 md:px-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-white">
          {/* About Us Description Section */}
          <div className="flex flex-col justify-center">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold text-center mb-6">
              Discover the Future of Fantasy Sports
            </h1>
            <p className="text-lg md:text-xl text-center text-gray-200 leading-relaxed">
              Welcome to the ultimate fantasy sports platform! Our app offers a variety of exciting and dynamic games designed for both casual and dedicated players. Dive into the world of fantasy cricket, football, and more, and experience the thrill of playing live games with friends or players from around the world. With easy sign-up, real-time contests, and seamless cash rewards, Stumpspectacles is your gateway to an engaging gaming experience.
            </p>
            <div className="flex justify-center mt-8">
              <a href="https://wa.link/indraadsgg">
                <button className="bg-gradient-to-r from-purple-600 to-indigo-500 px-12 py-3 rounded-full text-white font-semibold cursor-pointer transition duration-300 transform hover:scale-105 hover:shadow-lg">
                  Get Started
                </button>
              </a>
            </div>
          </div>

          {/* Image Section */}
          <div className="flex justify-center items-center">
            <div className="w-full max-w-[350px] md:max-w-[500px] rounded-xl overflow-hidden shadow-lg">
              <img
                src="/rb_2149216037.png"
                alt="Fantasy Cricket"
                className="w-full h-full object-cover rounded-xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
